.dataTables_wrapper .dataTables_processing {
  position: absolute !important;
  top: 0 !important;
  left: 50% !important;
  width: 100% !important;
  padding-top: 50px !important;
  height: 100% !important;
  margin-left: -50% !important;
  margin-top: -25px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  background-color: white !important;
}
