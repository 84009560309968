.drop-box {
  background: #F8F8F8;
  border: 5px dashed #DDD;
  width: 100%;
  height: 80px;
  text-align: center;
  padding-top: 25px;
  margin: 10px;
   }
.dragover {
  border: 5px dashed blue;
}
