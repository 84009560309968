  .wrong-ans{
    color: #ff3139;
  }
  .correct-ans{
    background-color: rgba(163, 255, 124, 0.86);
   }

  .repeated-item {
    border-bottom: 1px solid #ddd;
    box-sizing: border-box;
    height: 40px;
    padding-top: 10px; }
.virtual-repeat{
  height:80%;
  min-height:100px;
}

.sortable-list{
  tr{
    cursor: move;
  }
}

  .ui-sortable-helper {
    cursor: move;
    background: white;
    border:3px dashed gray;
  }
  td.active {
    color: white;
    background: rgb(66, 179, 66);
  }
.position_odgovor{
  span{
    display: inline-block;
  }
  p{
    display: inline-block;
  }
}
