#podesavanja{
  md-card{
    .md-headline{
      padding: 15px;
    }
    md-card-title{
      padding: 16px;
    }

  }

}
